<template>
	<b-tabs v-model="tabIndex">
		<template v-if="tabs.length > 1">
			<template
				v-for="(tabInfo, index) in tabs">
				<b-tab :key="index">
					<template slot="title">
						<router-link
							:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
							:to="{ name: tabInfo.name }"
							class="list-group-item btn text-left">
							{{ translate(tabInfo.translate_key) }}
						</router-link>
					</template>
					<template
						v-if="tabIndex === index"
						class="p-0">
						<router-view :key="routerViewKey" />
					</template>
				</b-tab>
			</template>
		</template>
		<template v-else>
			<router-view :key="routerViewKey" />
		</template>
	</b-tabs>
</template>

<script>
import { CustomPricing } from '@/translations';

export default {
	name: 'CustomPricesTabs',
	messages: [CustomPricing],
	data() {
		return {
			routerViewKey: '',
			tabIndex: 0,
			tabs: [
				{
					name: 'CustomPricesDeveloped',
					translate_key: 'developed_markets',
				},
				// {
				// 	name: 'CustomPricesEmerging',
				// 	translate_key: 'emerging_markets',
				// },
			],
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (Number(vm.$cookie.get('is_jns'))) {
				vm.$router.replace({ name: 'Home' });
			}
		});
	},
};
</script>

<style scoped>

</style>
